import type {GatsbyBrowser} from 'gatsby';
import {Helmet} from 'react-helmet';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => (
    <>
        <Helmet>
            <meta name="theme-color" content="#ffffff" />
            <link rel="shortcut icon" href="/images/favicons/favicon.svg" />
        </Helmet>
        {element}
    </>
);
